import { gql } from '@apollo/client'

export const AddMusic = gql`
    mutation AddMusic($roomId: ID!, $trackIds: [ID!]!) {
        addMusic(roomId: $roomId, trackIds: $trackIds) {
            id
        }
    }
`

export default AddMusic
