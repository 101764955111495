import { gql } from '@apollo/client'

export const Track = gql`
    query Track($id: ID!) {
        track(id: $id) {
            id
            album
            artist
            bitRate
            codec
            completed
            composer
            contributors {
                id
                username
            }
            created
            date
            duration
            files {
                bitRate
                duration
                key
                size
            }
            genre
            roomId
            size
            tags
            title
            track
            updated
        }
    }
`

export default Track
