import CurrentUser from '../queries/CurrentUser'
import { useQuery } from '@apollo/client'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { useEffect } from 'react'

export default function useCurrentRoom() {
    const { data } = useQuery(CurrentUser)
    const { currentRoom } = useAppContext()
    const dispatch = useAppDispatch()

    const user = data?.user
    const room = user?.rooms.find( r => r.id === currentRoom ) || user?.rooms[0]
    useEffect(() => {
        if (room)
            dispatch(Actions.setCurrentRoom(room.id))
    }, [room, user])
    return room
}
