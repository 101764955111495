import { from, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { authLink } from './auth'
import { errorLink } from './error'
import { httpLink } from './http'
import { wsLink } from './websocket'

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    httpLink,
)

export const linkChain = from([ authLink, errorLink, splitLink ])
