import { gql } from '@apollo/client'

export const AddAdmin = gql`
    mutation AddAdmin($roomId: ID!, $adminId: ID!) {
        addAdmin(roomId: $roomId, adminId: $adminId) {
            id
        }
    }
`

export default AddAdmin
