import { gql } from '@apollo/client'

export const Tracks = gql`
    query Tracks($params: TrackSearchParams, $cursor: String, $limit: Int) {
        tracks(params: $params, cursor: $cursor, limit: $limit) {
            cursor
            tracks {
                id
                album
                artist
                bitRate
                codec
                composer
                completed
                contributors {
                    id
                    username
                }
                created
                date
                duration
                files {
                    bitRate
                    duration
                    key
                    size
                }
                genre
                roomId
                size
                tags
                title
                track
                updated
            }
        }
    }
`

export default Tracks
