import { gql } from '@apollo/client'

export const TracksForAlbum = gql`
    query TracksForAlbum($roomId: ID, $artist: String!, $album: String!) {
        tracksForAlbum(roomId: $roomId, album: $album, artist: $artist) {
            id
            album
            artist
            bitRate
            codec
            composer
            completed
            contributors {
                id
                username
            }
            created
            date
            duration
            files {
                bitRate
                duration
                key
                size
            }
            genre
            roomId
            size
            tags
            title
            track
            updated
        }
    }
`

export default TracksForAlbum
