import { gql } from '@apollo/client'

export const RoomMembers = gql`
    query RoomMembers($roomId: ID!) {
        room(id: $roomId) {
            id
            members {
                avatar
                id
                lastActivity
                username
            }
        }
    }
`

export default RoomMembers
