import { useQuery } from '@apollo/client'
import RoomMembers from '../queries/RoomMembers'
import useCurrentRoom from '../hooks/useCurrentRoom'
import ProfileAvatar from '../profile/ProfileAvatar'
import useCurrentUser from '../hooks/useCurrentUser'
import RoomMemberList from './RoomMemberList'
import { useState } from 'react'

const avatarCount = 3

export default function RoomMemberSummary() {
    const room = useCurrentRoom()
    const user = useCurrentUser()
    const { data } = useQuery(RoomMembers, { skip: !room, variables: { roomId: room?.id } })
    const members = data?.room.members
    const [ showMemberList, setShowMemberList ] = useState(false)

    function toggleMemberList() {
        setShowMemberList(!showMemberList)
    }

    const avatars =  members?.filter( m => m.id !== user.id )
        .sort( (a, b) => b.lastActivity - a.lastActivity )
        .filter( (m, index) => index < avatarCount )
        .map( (member, index) => <ProfileAvatar key={index} user={member} position={index} /> )

    const additional = members && (members.length - 1) > avatarCount ?
        <div className="RoomMemberSummaryCount">+{ members.length - avatarCount }</div> : null

    return <div className="RoomMemberSummary" onClick={toggleMemberList}>
        { showMemberList ? <RoomMemberList /> : null }
        { avatars }
        { additional }
    </div>
}
