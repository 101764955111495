import { gql } from '@apollo/client'

export const RoomEvents = gql`
    subscription RoomEvents($id: ID!) {
        roomEvents(id: $id) {
            ... on AdminAdded {
                actor {
                    id
                    username
                }
                admin {
                    id
                    username
                }
                id
                room {
                    id
                    admins {
                        id
                        username
                    }
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                updated
            }
            ... on AdminRemoved {
                actor {
                    id
                    username
                }
                admin {
                    id
                    username
                }
                id
                room {
                    id
                    admins {
                        id
                        username
                    }
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                updated
            }
            ... on ChatAdded {
                actor {
                    id
                    username
                }
                id
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                text
                updated
            }
            ... on ChatRemoved {
                actor {
                    id
                    username
                }
                id
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                updated
            }
            ... on ChatUpdated {
                actor {
                    id
                    username
                }
                id
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                text
                updated
            }
            ... on MemberAdded {
                actor {
                    id
                    username
                }
                id
                member {
                    id
                    username
                }
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                updated
            }
            ... on MemberRemoved {
                actor {
                    id
                    username
                }
                id
                member {
                    id
                    username
                }
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                updated
            }
            ... on MemberUpdated {
                id
                member {
                    id
                    username
                }
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                updated
            }
            ... on RequestAdded {
                actor {
                    id
                    username
                }
                id
                request {
                    id
                    playTime
                    track {
                        id
                        album
                        artist
                        bitRate
                        codec
                        composer
                        created
                        date
                        duration
                        files {
                            bitRate
                            duration
                            key
                            size
                        }
                        genre
                        roomId
                        size
                        tags
                        title
                        track
                        updated
                        contributors {
                            id
                            username
                        }
                    }
                    user {
                        id
                        lastActivity
                        username
                    }
                }
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                    queue {
                        id
                        playTime
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            contributors {
                                id
                                username
                            }
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                        }
                        user {
                            id
                            username
                        }
                    }
                }
                updated
            }
            ... on RequestRemoved {
                actor {
                    id
                    username
                }
                id
                request {
                    id
                    playTime
                    track {
                        id
                        album
                        artist
                        bitRate
                        codec
                        composer
                        contributors {
                            id
                            username
                        }
                        created
                        date
                        duration
                        files {
                            bitRate
                            duration
                            key
                            size
                        }
                        genre
                        roomId
                        size
                        tags
                        title
                        track
                        updated
                    }
                    user {
                        id
                        username
                    }
                }
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                    queue {
                        id
                        playTime
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            contributors {
                                id
                                username
                            }
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                        }
                        user {
                            id
                            username
                        }
                    }
                }
                updated
            }
            ... on RequestSkipped {
                actor {
                    id
                    username
                }
                id
                request {
                    id
                    playTime
                    track {
                        id
                        album
                        artist
                        bitRate
                        codec
                        composer
                        created
                        date
                        duration
                        files {
                            bitRate
                            duration
                            key
                            size
                        }
                        genre
                        roomId
                        size
                        tags
                        title
                        track
                        updated
                        contributors {
                            id
                            username
                        }
                    }
                    user {
                        id
                        username
                    }
                }
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                    queue {
                        id
                        playTime
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            contributors {
                                id
                                username
                            }
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                        }
                        user {
                            id
                            username
                        }
                    }
                }
                updated
            }
            ... on RequestStarted {
                actor {
                    id
                    username
                }
                id
                request {
                    id
                    playTime
                    track {
                        id
                        album
                        artist
                        bitRate
                        codec
                        composer
                        created
                        date
                        duration
                        files {
                            bitRate
                            duration
                            key
                            size
                        }
                        genre
                        roomId
                        size
                        tags
                        title
                        track
                        updated
                        contributors {
                            id
                            username
                        }
                    }
                    user {
                        id
                        username
                    }
                }
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                    queue {
                        id
                        playTime
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            contributors {
                                id
                                username
                            }
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                        }
                        user {
                            id
                            username
                        }
                    }
                }
                updated
            }
            ... on RoomUpdated {
                actor {
                    id
                    username
                }
                id
                room {
                    admins {
                        id
                        username
                    }
                    created
                    id
                    invites {
                        email
                        id
                        issuer {
                            id
                            username
                        }
                        user {
                            id
                            username
                        }
                    }
                    members {
                        id
                        lastActivity
                        username
                    }
                    queue {
                        id
                        playTime
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            contributors {
                                id
                                username
                            }
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                        }
                        user {
                            avatar
                            id
                            lastActivity
                            username
                        }
                    }
                    name
                    owner {
                        id
                        username
                    }
                    spinning
                    topic
                    updated
                }
                updated
                updates {
                    name
                    spinning
                    topic
                    invite
                }
            }
            ... on TracksAdded {
                actor {
                    id
                    username
                }
                id
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                tracks {
                    id
                    album
                    artist
                    bitRate
                    codec
                    composer
                    contributors {
                        id
                        username
                    }
                    created
                    date
                    duration
                    files {
                        bitRate
                        duration
                        key
                        size
                    }
                    genre
                    roomId
                    size
                    tags
                    title
                    track
                    updated
                }
                updated
            }
            ... on Subscribed {
                id
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                updated
            }
            ... on TracksRemoved {
                actor {
                    id
                    username
                }
                id
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                tracks {
                    id
                    album
                    artist
                    bitRate
                    codec
                    composer
                    contributors {
                        id
                        username
                    }
                    created
                    date
                    duration
                    files {
                        bitRate
                        duration
                        key
                        size
                    }
                    genre
                    roomId
                    size
                    tags
                    title
                    track
                    updated
                }
                updated
            }
            ... on TrackUpdated {
                actor {
                    id
                    username
                }
                id
                room {
                    id
                    members {
                        id
                        lastActivity
                        username
                    }
                }
                track {
                    id
                    album
                    artist
                    bitRate
                    codec
                    composer
                    contributors {
                        id
                        username
                    }
                    created
                    date
                    duration
                    files {
                        bitRate
                        duration
                        key
                        size
                    }
                    genre
                    roomId
                    size
                    tags
                    title
                    track
                    updated
                }
                updated
            }
        }
    }`

export default RoomEvents
