import defaultProfileIcon from '../img/default-profile.jpg'
import { useEffect, useState } from 'react'

export default function ProfileAvatar(props) {
    const { user } = props
    const [ profileImage, setProfileImage ] = useState(user?.avatar ?? defaultProfileIcon)

    useEffect(() => {
        setProfileImage(user?.avatar ?? defaultProfileIcon)
    }, [user])

    const idleMs = Date.now() - (user?.lastActivity ?? 0)

    let status = ''
    if (idleMs < 300000) {
        status = 'ProfileStatusRecent'
    } else if (idleMs < 3600000) {
        status = 'ProfileStatusIdle'
    }

    return <div className="ProfileAvatarContainer">
        <img className="ProfileAvatar"
             src={profileImage}
             alt="User Avatar"
             onError={() => setProfileImage(defaultProfileIcon)}
        />
        <div className={`ProfileStatusIndicator ${status}`} />
    </div>

}
