import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { emailIsValid } from '../util/validation'
import Spinner from '../widgets/Spinner'
import CurrentUser from '../queries/CurrentUser'
import LoginWithEmail from '../queries/LoginWithEmail'

export default function EmailLoginModal(props) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [loginMutation, { loading, data, error, reset }] = useMutation(LoginWithEmail, {
        fetchPolicy: 'no-cache',
        update: addUserToCache
    })

    if (data?.loginWithEmail?.accessToken) {
        const { loginWithEmail: { accessToken, refreshToken } } = data
        localStorage.setItem('at', accessToken)
        localStorage.setItem('rt', refreshToken)
        reset()
        props.loggedIn()
    } else if (error) {
        console.log(`error => ${JSON.stringify(error)}`)
        if (error.networkError?.statusCode === 401) {
            setErrorMessage('Invalid email address or password.  Please try again.')
        } else if (error.networkError?.statusCode === 403) {
            props.show('verifyEmail')
        } else {
            setErrorMessage(error.message)
        }
        reset()
    }

    function addUserToCache(cache, { data }) {
        // Delay the cache update until after the tokens have been written to localStorage
        setTimeout(() => {
            cache.writeQuery({
                query: CurrentUser,
                data: data.loginWithEmail
            })
        }, 10)
    }

    function cancel(e) {
        reset()
        props.show('') // close
    }

    function createAccount(e) {
        if (loading) {
            cancel(e)
        }
        props.show('emailCreateAccount')
    }

    function emailUpdated(e) {
        setErrorMessage('')
        setEmail(e.target.value)
    }

    async function logIn(e) {
        const emailProblem = validateEmail()
        if (emailProblem) {
            setErrorMessage(emailProblem)
            return
        }
        const passwordProblem = validatePassword()
        if (passwordProblem) {
            setErrorMessage(passwordProblem)
            return
        }
        try {
            await loginMutation({ variables: { email, password }})
        } catch (err) {
            setErrorMessage(err.message)
        }
    }

    function passwordUpdated(e) {
        setErrorMessage('')
        setPassword(e.target.value)
    }

    function validateEmail() {
        if (!emailIsValid(email)) return 'Invalid email address.'
        return null
    }

    function validatePassword() {
        if (password.length < 1) return 'You must supply a password to log in.'
        return null
    }

    return (
        <div className="Dimmer">
            <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp">
                <div style={{ display: 'grid', gridTemplateColumns: '100px 1fr', gridColumnGap: '5px', gridRowGap: '3px' }}>
                    <div style={{ gridRow: 1, gridColumn: 1, textAlign: 'right' }}>Email:</div>
                    <div style={{ gridRow: 1, gridColumn: 2, textAlign: 'left' }}>
                        <input type="email" style={{ width: '100%' }} value={email} onChange={emailUpdated}/>
                    </div>
                    <div style={{ gridRow: 2, gridColumn: 1, textAlign: 'right' }}>Password:</div>
                    <div style={{ gridRow: 2, gridColumn: 2, textAlign: 'left' }}>
                        <input type="password" style={{ width: '100%' }} value={password} onChange={passwordUpdated}/>
                    </div>
                    <div style={{ gridRow: 3, gridColumn: 1, textAlign: 'right' }}>
                        <div style={{ height: '25px', marginTop: '8px', marginRight: '10px', marginBottom: '0px' }}>
                            { loading ? <Spinner /> : null }
                        </div>
                    </div>
                    <div style={{ gridRow: 3, gridColumn: 2, marginTop: '10px', textAlign: 'left' }}>
                        <button style={{ marginRight: '10px' }} onClick={cancel}>Cancel</button>
                        <button style={{ marginRight: '10px' }} disabled={loading} onClick={logIn}>Log In</button>
                    </div>
                    <div style={{ gridRow: 4, gridColumn: 2, textAlign: 'right' }}>
                        <p className="Link" style={{ fontSize: '12px' }} onClick={createAccount}>Need an account?</p>
                    </div>
                    { errorMessage ?
                        <div style={{ gridRow: 6, gridColumnStart: 1, gridColumnEnd: 3, textAlign: 'center' }}>
                            <p className="ErrorMessage">{ errorMessage }</p>
                        </div>
                        : null }
                </div>
            </div>
            </div>
        </div>
    )
}