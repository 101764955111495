import { gql } from '@apollo/client'

export const RemoveMember = gql`
    mutation RemoveMember($roomId: ID!, $memberId: ID!) {
        removeMember(roomId: $roomId, memberId: $memberId) {
            id
        }
    }
`

export default RemoveMember
