import { useState } from 'react'
import { useAppDispatch } from '../AppContext.js'
import { Actions } from '../AppReducer'
import EmailCreateAccountModal from './EmailCreateAccountModal'
import EmailLoginModal from './EmailLoginModal'
import VerificationRequiredModal from './VerificationRequiredModal'
import VerificationFailedModal from './VerificationFailedModal'
import VerificationSucceededModal from './VerificationSucceededModal'
import VerifyingModal from './VerifyingModal'
import DiskImage from '../img/music-disk.png'
import '../App.css'

export default function Login() {

    const [showing, setShowing] = useState('')
    const appDispatch = useAppDispatch()
    global.uiDispatch = appDispatch // A cheat to allow us to dispatch directly from Api functions

    function loggedIn() {
        setShowing('')
        appDispatch(Actions.logIn())
    }

    function show(dialog) {
        setShowing(dialog)
    }

    const queryParams = new URLSearchParams(window.location.search)
    const verificationCode = queryParams.get('code')

    let dialog
    if (!showing && verificationCode) {
        dialog = <VerifyingModal show={show} code={verificationCode} />
    } else if (showing === 'verified') {
        dialog = <VerificationSucceededModal show={show} />
    } else if (showing === 'verifyFailed') {
        dialog = <VerificationFailedModal show={show} />
    } else if (showing === 'emailLogin') {
        dialog = <EmailLoginModal show={show} loggedIn={loggedIn} />
    } else if (showing === 'emailCreateAccount') {
        dialog = <EmailCreateAccountModal show={show} />
    } else if (showing === 'verifyEmail') {
        dialog = <VerificationRequiredModal show={show} />
    }

    return (
        <div className="Login">
            <div style={{ margin: '20px' }}>
                <img src={DiskImage} alt="Tunistry Icon" style={{ width: '100px' }}/>
                <h1>Tunistry</h1>
                <p>Your music, your way</p>
                <div className="ContentBox HalfPadding">
                    <p className="Link" onClick={() => show('emailLogin')}>Log in with your email address</p>
                </div>
            </div>
            { dialog }
        </div>
    )
}
