import { useEffect, useRef, useState } from 'react'

export default function Sheet(props) {

    const [bottom, setBottom] = useState(-1000)
    const ref = useRef(null)
    useEffect(() => { setBottom(props.open ? 0 : -(ref.current.clientHeight)) }, [props])

    return (
        <div className="Sheet" style={{ bottom }} ref={ref}>
            <div className="SheetControls">
                <span className="material-symbols-outlined TrackControlButton" onClick={() => props.onClose()}>cancel</span>
            </div>
            <div className="SheetTitle">{ props.title }</div>
            <div className="SheetContent">
                { props.children }
            </div>
        </div>
    )
}