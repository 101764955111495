import useUserIsCurrentRoomAdmin from '../hooks/useUserIsCurrentRoomAdmin'

export default function ListenControls(props) {

    const isAdmin = useUserIsCurrentRoomAdmin()
    const activeClass = 'material-symbols-outlined TrackControlButton ButtonActive'
    const inactiveClass = 'material-symbols-outlined TrackControlButton'

    return (
        <div key={1} className="ListenControls">
            { isAdmin ?
                [
                    <span key={1} className={ inactiveClass } onClick={props.showRoomDetails}>room_preferences</span>,
                    <span key={2} className={ inactiveClass } onClick={props.showAddMusic}>library_music</span>,
                    <span key={3} className={ inactiveClass } onClick={props.showQueueMusic}>playlist_add</span>
                ]
                : null
            }
            <span className={ props.queueActive ? activeClass : inactiveClass } onClick={props.toggleQueue}>queue_music</span>
            <span className={ props.roomsActive ? activeClass : inactiveClass } onClick={props.toggleRoomList}>meeting_room</span>
        </div>
    )
}
