import { gql } from '@apollo/client'

export const MultipartUploadParts = gql`
    query MultipartUploadParts($uploadId: ID!, $hash: String!) {
        multipartUploadParts(uploadId: $uploadId, hash: $hash) {
            PartNumber,
            LastModified,
            ETag,
            Size,
            ChecksumCRC32,
            ChecksumCRC32C,
            ChecksumSHA1,
            ChecksumSHA256,
        }
    }
`

export default MultipartUploadParts
