import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import useQueueTrack from '../hooks/useQueueTrack'
import { hoursMinsSecs } from '../util/time'
import Spinner from '../widgets/Spinner'
import SearchTrackControls from '../search/SearchTrackControls'
import TracksForAlbum from '../queries/TracksForAlbum'
import { useQuery } from '@apollo/client'
import useCurrentRoom from '../hooks/useCurrentRoom'

export default function AlbumView(props) {

    const appState = useAppContext()
    const dispatch = useAppDispatch()

    const { mainSheetState: { data: sourceTrack } } = appState
    const { artist, album, roomId } = sourceTrack
    const room = useCurrentRoom()
    const queueTrack = useQueueTrack(roomId && room?.id)

    const { data, loading } = useQuery(TracksForAlbum, { variables: { roomId, artist, album } })
    const albumData = data?.tracksForAlbum

    const tracks = sortedTracks().map( (track, index) => [
        <div className={ 'AlbumTrackNumber' + (index % 2 === 0 ? ' Even' : ' Odd') } style={{ gridRow: index + 1, gridColumn: 1 }} key={`${track.hash}-album-a`}>{ trackNumber(track) }</div>,
        <div className={ 'AlbumTrackDuration' + (index % 2 === 0 ? ' Even' : ' Odd') } style={{ gridRow: index + 1, gridColumn: 2 }} key={`${track.hash}-album-b`}>{ hoursMinsSecs(track.duration) }</div>,
        <div className={ 'AlbumTrackControls' + (index % 2 === 0 ? ' Even' : ' Odd') } style={{ gridRow: index + 1, gridColumn: 3 }} key={`${track.hash}-album-c`}><SearchTrackControls track={track} /></div>,
        <div className={ 'AlbumTrackTitle' + (index % 2 === 0 ? ' Even' : ' Odd') } style={{ gridRow: index + 1, gridColumn: 4 }} key={`${track.hash}-album-d`}><div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showAlbum', data: track }))}>{track.title}</div></div>
    ])

    let trackList
    if (loading) trackList = <div className="AlbumLoading"><Spinner/></div>
    else trackList = <div className="AlbumGrid">{ tracks }</div>

    function sortedTracks() {
        return albumData?.map( t => t ).sort((a, b) => (Number(trackNumber(a)) < Number(trackNumber(b))) ? -1 : 1 ) ?? []
    }

    function trackNumber(track) {
        return track.track?.split(/\//)[0] ?? ''
    }

    async function queueAll() {
        for (const track of sortedTracks()) {
            await queueTrack(track, true)
        }
        dispatch(Actions.showToast({ content: <span>Added all tracks from <b>{ sourceTrack.album }</b></span> }))
    }

    return (
        <div className="AlbumView">
            <div className="AlbumViewHeader">
                <span className="AlbumTitle">{ sourceTrack.album }</span>
                <span className="AlbumSeparator">by</span>
                <span className="AlbumArtist">{ sourceTrack.artist }</span>
            </div>
            <div className="AlbumGroupControls">
                <div className="AlbumGroupControlLabel">Queue All:</div>
                <div><span className="material-symbols-outlined TrackControlButton" onClick={queueAll}>playlist_add</span></div>
            </div>
            <div className="AlbumViewContent">
                { trackList }
            </div>
        </div>
    )
}
