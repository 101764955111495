import { gql } from '@apollo/client'

export const LoginWithEmail = gql`
    mutation LogInWithEmail($email: String!, $password: String!) {
        loginWithEmail(email: $email, password: $password) {
            user {
                id
                created
                email
                invites {
                    id
                    issuer {
                        id
                        username
                    }
                    room {
                        id
                        name
                        owner {
                            id
                            username
                        }
                    }
                }
                rooms {
                    id
                    name
                    topic
                    spinning
                    admins {
                        id
                        username
                    }
                    members {
                        id
                        username
                    }
                }
                updated
                username
            }
            refreshToken
            accessToken
        }
    }
`

export default LoginWithEmail
