import { gql } from '@apollo/client'

export const AddChat = gql`
    mutation AddChat($roomId: ID!, $text: String!) {
        addChat(roomId: $roomId, text: $text) {
            id
        }
    }
`

export default AddChat
