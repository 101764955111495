import { gql } from '@apollo/client'

export const CreateTrack = gql`
    mutation CreateTrack($hash: String!) {
        createTrack(hash: $hash) {
            created
            completed
            id
            updated
            userId
        }
    }
`

export default CreateTrack
