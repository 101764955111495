import { useEffect, useState } from 'react'
import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import Spinner from '../widgets/Spinner'
import useCurrentRoom from '../hooks/useCurrentRoom'
import useCurrentUser from '../hooks/useCurrentUser'
import { useQuery } from '@apollo/client'
import Tracks from '../queries/Tracks'
import QueueMusicTrackControls from './QueueMusicTrackControls'

export default function QueueMusic(props) {

    const dispatch = useAppDispatch()
    const user = useCurrentUser()
    const room = useCurrentRoom()
    const roomId = props.roomId

    const lastSearchData = localStorage.getItem('lastSearch')
    const lastSearchParams = lastSearchData ? JSON.parse(lastSearchData) : {}
    const blankParams = { searchType: lastSearchParams.searchType ?? 'ARTIST', text: lastSearchParams.text ?? '', tags: lastSearchParams.tags ?? '', roomId }
    const [params, setParams] = useState(blankParams)

    useEffect(() => { setParams(blankParams) }, [user, roomId])

    const {
        loading: tracksLoading,
        error: tracksError,
        data: trackData,
        fetchMore
    } = useQuery(Tracks, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
            limit: 100,
            params: { ...params, tags: params.tags.split(/\s+/).filter( x => x) }
        }
    })
    const trackList = trackData?.tracks?.tracks

    useEffect(() => { setParams(blankParams) }, [user, roomId])

    function handleScroll(e) {
        const {scrollTop, scrollHeight, clientHeight} = e.target
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100
        )
        if (!tracksLoading && position > 90 && trackData?.tracks?.cursor) {
            console.log(`fetching more tracks`)
            fetchMore({ variables: { cursor: trackData.tracks.cursor }})
        }
    }

    function paramsUpdated(field, value) {
        const newParams = { ...params }
        newParams[field] = value
        setParams(newParams)
        localStorage.setItem('lastSearch', JSON.stringify(newParams))
    }

    return (
        <div className="SearchPage">
            <div className="SheetInstructions">
                <span className="SheetInstructionsSpan">Search for music in { room.name }.  Click the <span className="material-symbols-outlined" style={{ fontSize: '16px' }}>playlist_add</span> button
                next to a track to add it to the request queue.</span>
            </div>
            <div className="SearchParams">
                <div className="SearchParamsGrid">
                    <div className="GridLabel" style={{ gridArea: 'searchByLabel' }}>Search</div>
                    <div className="GridText" style={{ gridArea: 'searchBy' }}>
                        <select className="SearchTypeSelect" value={params.searchType} onChange={(e) => paramsUpdated('searchType', e.target.value)}>
                            <option value="ARTIST">Artist</option>
                            <option value="TITLE">Title</option>
                            <option value="ALBUM">Album</option>
                        </select>
                        <input className="SearchTextInput" type="text" style={{ width: '200px' }} value={params.text} onChange={(e) => paramsUpdated('text', e.target.value)} />
                    </div>
                    <div className="GridLabel" style={{ gridArea: 'tagsLabel' }}>Tags</div>
                    <div className="GridText" style={{ gridArea: 'tags' }}>
                        <input type="text" style={{ width: '200px' }} value={params.tags} onChange={(e) => paramsUpdated('tags', e.target.value)} />
                    </div>
                </div>
            </div>

            <div className="SearchResults"  onScroll={handleScroll}>
                { trackList?.length > 0 ?
                    <div style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr 1fr 1fr' }}>
                        { trackList?.map( (track, index) => [
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 1}} key={`${index}a`}>
                                <QueueMusicTrackControls track={track} roomId={ roomId } />
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 2 }} key={`${index}b`}>
                                <div>{track.artist}</div>
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 3 }} key={`${index}c`}>
                                <div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showTrack', data: track.id }))}>{track.title ?? '[ untitled ]'}</div>
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 4 }} key={`${index}d`}>
                                <div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showAlbum', data: track }))}>{track.album}</div>
                            </div>
                        ] ) }
                    </div>
                :
                    <div className="SearchNoResults">
                        <p>No matches for this search.</p>
                        <p>To add music you can <span className="Link" onClick={() => dispatch(Actions.navigateTo('upload'))}>upload your music files</span> and add them to the room.</p>
                    </div>
                }
                { tracksLoading ? <Spinner /> : null }
            </div>
        </div>
    )
}
