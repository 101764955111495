import { useState } from 'react'
import Spinner from '../widgets/Spinner'
import EditText from '../widgets/EditText'
import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { useMutation } from '@apollo/client'
import AddRoom from '../queries/AddRoom'

export default function CreateRoom() {

    const dispatch = useAppDispatch()
    const [addRoomMutation, { loading }] = useMutation(AddRoom)

    const [name, setName] = useState('Listening Room')
    const [topic, setTopic] = useState('Welcome to Tunistry')

    const spinner = loading ? <Spinner /> : null

    async function makeUserRoom() {
        await addRoomMutation({ variables: { data: { name, topic } } })
        dispatch(Actions.dismissMainSheet())
    }

    return (
        <div>
            <div className="RoomDetailsGrid">
                <div className="GridLabel" style={{ gridArea: "nameLabel" }}>Name</div>
                <div className="GridText" style={{ gridArea: "name" }}><EditText editEnabled={true} text={name} updateText={(e) => setName(e.target.value)}/></div>
                <div className="GridLabel" style={{ gridArea: "topicLabel" }}>Topic</div>
                <div className="GridText" style={{ gridArea: "topic" }}><EditText editEnabled={true} text={topic} updateText={(e) => setTopic(e.target.value)}/></div>
            </div>
            <div className="CreateRoomButton">
            <button disabled={loading} onClick={makeUserRoom}>Create Room</button>
            { spinner }
            </div>
        </div>
    )
}
