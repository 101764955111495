import { gql } from '@apollo/client'

export const UpdateRoom = gql`
    mutation UpdateRoom($roomId: ID!, $updates: UpdateRoomInput!) {
        updateRoom(roomId: $roomId, updates: $updates) {
            id
            room {
                id
                invites {
                    email
                    id
                    issuer {
                        id
                        username
                    }
                    user {
                        id
                        username
                    }
                }
                name
                topic
                spinning
                owner {
                    id
                    username
                }
                admins {
                    id
                    username
                }
                members {
                    id
                    username
                }
            }
            updated
        }
    }
`

export default UpdateRoom
