import { useEffect, useState } from 'react'
import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import ImportControls from './ImportControls'
import Spinner from '../widgets/Spinner'
import useCurrentRoom from '../hooks/useCurrentRoom'
import useCurrentUser from '../hooks/useCurrentUser'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import Tracks from '../queries/Tracks'
import AddMusicMutation from '../queries/AddMusic'

export default function ImportMusic(props) {

    const dispatch = useAppDispatch()
    const client = useApolloClient()
    const user = useCurrentUser()
    const room = useCurrentRoom()
    const roomId = props.roomId

    const lastSearchData = localStorage.getItem('lastImport')
    const lastSearchParams = lastSearchData ? JSON.parse(lastSearchData) : {}
    const blankParams = { searchType: lastSearchParams.searchType ?? 'ARTIST', text: lastSearchParams.text ?? '', tags: lastSearchParams.tags ?? '' }
    const [params, setParams] = useState(blankParams)
    const [adding, setAdding] = useState('')
    const [abortController, setAbortController] = useState(new AbortController())

    useEffect(() => { setParams(blankParams) }, [user])

    const {
        loading: tracksLoading,
        error: tracksError,
        data: trackData,
        fetchMore
    } = useQuery(Tracks, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
            limit: 100,
            params: { ...params, tags: params.tags.split(/\s+/).filter( x => x) }
        }
    })
    const trackList = trackData?.tracks?.tracks

    const [addMusicMutation] = useMutation(AddMusicMutation)

    useEffect(() => { setParams(blankParams) }, [user])

    async function confirmAddAll(e) {
        dispatch(Actions.showModal({
            action: 'confirmation',
            text: 'Are you sure you want to add all tracks that match this search? Depending on how many tracks match this could take a while.',
            confirmAction: addAllSearchResults,
            confirmText: 'Add All Matching Tracks',
            cancelAction: () => abortController.abort()
        }))
    }

    async function addAllSearchResults() {
        let addCursor
        let totalAdded = 0
        let finished = false
        setAdding('all')
        while (!finished) {
            console.log(`addCursor => ${addCursor}`)
            const results = await client.query({
                query: Tracks,
                fetchPolicy: 'cache-first',
                variables: { cursor: addCursor, limit: 100, params },
            })
            console.log(`results => ${JSON.stringify(results)}`)
            if (results.data?.tracks?.tracks?.length) {
                const trackIds = results.data.tracks.tracks.map( item => item.id )
                const added = await addMusicMutation({ variables: { roomId, trackIds }})
                console.log(`added => ${JSON.stringify(added)}`)
                totalAdded += added.data.addMusic.length
            }
            addCursor = results.data?.tracks?.cursor
            if (!addCursor) finished = true
            if (abortController.signal.aborted) finished = true
        }
        setAdding('')
        return <span>Added <b>{ totalAdded }</b> track{ totalAdded > 1 ? 's' : ''}.</span>
    }

    function handleScroll(e) {
        const {scrollTop, scrollHeight, clientHeight} = e.target
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100
        )
        if (!tracksLoading && position > 90 && trackData?.tracks?.cursor) {
            console.log(`fetching more tracks`)
            fetchMore({ variables: { cursor: trackData.tracks.cursor }})
        }
    }

    function paramsUpdated(field, value) {
        const newParams = { ...params }
        newParams[field] = value
        setParams(newParams)
        localStorage.setItem('lastImport', JSON.stringify(newParams))
    }

    return (
        <div className="SearchPage">
            <div className="SheetInstructions">
                <span className="SheetInstructionsSpan">Search for music from your library to import to { room.name }.  Click the <span className="material-symbols-outlined" style={{ fontSize: '16px' }}>add_circle</span> button
                next to a track to import them one at a time, or click <span className="material-symbols-outlined" style={{ fontSize: '16px' }}>post_add</span> to add all tracks that
                match your search.</span>
            </div>
            <div className="SearchParams">
                <div className="SearchParamsGrid">
                    <div className="GridLabel" style={{ gridArea: 'searchByLabel' }}>Search</div>
                    <div className="GridText" style={{ gridArea: 'searchBy' }}>
                        <select className="SearchTypeSelect" value={params.searchType} onChange={(e) => paramsUpdated('searchType', e.target.value)}>
                            <option value="ARTIST">Artist</option>
                            <option value="TITLE">Title</option>
                            <option value="ALBUM">Album</option>
                        </select>
                        <input className="SearchTextInput" type="text" style={{ width: '200px' }} value={params.text} onChange={(e) => paramsUpdated('text', e.target.value)} />
                    </div>
                    <div className="GridLabel" style={{ gridArea: 'tagsLabel' }}>Tags</div>
                    <div className="GridText" style={{ gridArea: 'tags' }}>
                        <input type="text" style={{ width: '200px' }} value={params.tags} onChange={(e) => paramsUpdated('tags', e.target.value)} />
                    </div>
                    <div className="GridLabel" style={{ gridArea: 'buttonsLabel' }}>Add All</div>
                    <div className="GridText" style={{ gridArea: 'buttons' }}>
                        { adding === 'all' ? <Spinner/> :
                            <span className="material-symbols-outlined TrackControlButton"
                                  onClick={(e) => confirmAddAll()}>post_add</span>
                        }
                    </div>
                </div>
            </div>

            <div className="SearchResults"  onScroll={handleScroll}>
                { trackList?.length > 0 ?
                    <div style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr 1fr 1fr' }}>
                        { trackList?.map( (track, index) => [
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 1}} key={`${index}a`}>
                                <ImportControls track={track} addToRoom={props.roomId} />
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 2 }} key={`${index}b`}>
                                <div>{track.artist}</div>
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 3 }} key={`${index}c`}>
                                <div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showTrack', data: track.id }))}>{track.title ?? '[ untitled ]'}</div>
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 4 }} key={`${index}d`}>
                                <div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showAlbum', data: track }))}>{track.album}</div>
                            </div>
                        ] ) }
                    </div>
                :
                    <div className="SearchNoResults">
                        <p>No matches for this search.</p>
                        <p>To add music you can <span className="Link" onClick={() => dispatch(Actions.navigateTo('upload'))}>upload your music files</span> or purchase new digital music from one of our affiliates.</p>
                    </div>
                }
                { tracksLoading ? <Spinner /> : null }
            </div>
        </div>
    )
}
