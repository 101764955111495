import { gql } from '@apollo/client'

export const AddRequest = gql`
    mutation AddRequest($roomId: ID!, $trackId: ID!) {
        addRequest(roomId: $roomId, trackId: $trackId) {
            id
        }
    }
`

export default AddRequest
