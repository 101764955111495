
export const left = (text, size) => {
    if (text.length > size) return text.slice(0, size)
    else {
        const padding = size - text.length
        for (let x = 0; x < padding; x++) { text += ' ' }
        return text
    }
}

export const right = (text, size) => {
    if (text.length > size) return text.slice(-size)
    else {
        const padding = size - text.length
        for (let x = 0; x < padding; x++) { text = ' ' + text }
        return text
    }
}

export function sizeFor(bytes) {
    if (typeof bytes !== 'number') return 'Unknown'
    else if (bytes < 1000) return `${bytes} B`
    else if (bytes < 1000000) return `${Math.round(bytes / 1000)} KB`
    else return `${Math.round(bytes / 1000000)} MB`
}
