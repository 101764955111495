import { useAppContext } from './AppContext'
import AlertModal from './widgets/AlertModal'
import ConfirmModal from './widgets/ConfirmModal'
import Modal from './widgets/Modal'
import ProgressModal from './widgets/ProgressModal'

export default function MainModal() {
    const appState = useAppContext()
    const { mainModalOpen, mainModalState } = appState

    let modalContent
    switch (mainModalState?.action) {
        case 'alert':
            modalContent = <AlertModal
                text={mainModalState.text}
                okText={mainModalState.okText ?? 'OK'}
                />
            break
        case 'confirmation':
            modalContent = <ConfirmModal
                text={mainModalState.text}
                confirmText={mainModalState.confirmText}
                confirmAction={mainModalState.confirmAction}
                cancelAction={mainModalState.cancelAction}
                />
            break
        case 'progress':
            modalContent = <ProgressModal
                text={mainModalState.text}
                action={mainModalState.task}
                />
        default:
            // no content
    }

    return (
        <Modal open={mainModalOpen}>
            { modalContent }
        </Modal>
    )
}
