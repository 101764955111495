import { gql } from '@apollo/client'

export const AddRoom = gql`
    mutation AddRoom($data: UpdateRoomInput!) {
        addRoom(data: $data) {
            id
            name
            topic
            spinning
            admins {
                id
                username
            }
            members {
                id
                username
            }
        }
    }
`

export default AddRoom
