import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient, MessageType, stringifyMessage } from 'graphql-ws'

let pingSentTime

export const wsLink = new GraphQLWsLink(createClient({
    connectionParams: () => ({ authToken: localStorage.getItem('at') }),
    keepAlive: 60 * 5 * 1000, // 5 mins
    on: {
        closed: (event) => logEvent('closed', { event }),
        connected: (socket, payload, wasRetry) => {
            logEvent('connected', { socket, payload, wasRetry })
            pingSentTime = Date.now()
            socket.send(
                stringifyMessage({
                    type: MessageType.Ping,
                    payload,
                }),
            )
        },
        connecting: (isRetry) => logEvent('connecting', { isRetry }),
        error: (error) => logEvent('error', { error }),
        message: (message) => logEvent('message', { message }),
        opened: (socket) => logEvent('opened', { socket }),
        ping: (received, payload) => logEvent('ping', { received, payload }),
        pong: (received, payload) => {
            if (received && !global.measuredRTT) {
                const now = Date.now()
                global.measuredRTT = now - pingSentTime
                global.serverOffset = Math.floor(payload.time + (global.measuredRTT / 2) - now)
                console.log(`  server time: ${payload.time}`)
                console.log(`     our time: ${now}`)
                console.log(` measured RTT: ${global.measuredRTT}`)
                console.log(`server offset: ${global.serverOffset}`)
            }
            logEvent('pong', { received, payload })
        }
    },
    url: process.env.REACT_APP_WEBSOCKET_URL
}))

function logEvent(type, data) {
    let value
    switch (type) {
        case 'closed':
            const { event } = data
            value = `code: ${event.code} reason: ${event.reason} wasClean: ${event.wasClean}`
            break
        case 'connected':
            value = `payload: ${JSON.stringify(data.payload)}`
            break
        case 'connecting':
            value = `isRetry: ${data.isRetry}`
            break
        case 'error':
            const { message, stack } = data.error
            value = `message: ${message} stack: ${stack}`
            break
        case 'message': {
            const { payload, type } = data.message
            value = `type: ${type} payload: ${JSON.stringify(payload)}`
            break
        }
        case 'opened':
            value = ''
            break
        case 'ping': {
            const { received, payload } = data
            value = `received: ${received} payload: ${JSON.stringify(payload)}`
            break
        }
        case 'pong': {
            const { received, payload } = data
            value = `received: ${received} payload: ${JSON.stringify(payload)}`
            break
        }
        default:
            value = 'unknown type'
    }
    // console.log(`websocket: ${type}: ${value}`)
}
