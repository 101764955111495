import { gql } from '@apollo/client'

export const History = gql`
    query History($roomId: ID!, $cursor: ID) {
        history(roomId: $roomId, cursor: $cursor) {
            cursor
            events {
                ... on AdminAdded {
                    actor {
                        id
                        username
                    }
                    admin {
                        id
                        username
                    }
                    id
                    room {
                        id
                    }
                    updated
                }
                ... on AdminRemoved {
                    actor {
                        id
                        username
                    }
                    admin {
                        id
                        username
                    }
                    id
                    room {
                        id
                    }
                    updated
                }
                ... on ChatAdded {
                    actor {
                        id
                        username
                    }
                    id
                    room {
                        id
                    }
                    text
                    updated
                }
                ... on ChatRemoved {
                    actor {
                        id
                        username
                    }
                    id
                    room {
                        id
                    }
                    updated
                }
                ... on ChatUpdated {
                    actor {
                        id
                        username
                    }
                    id
                    room {
                        id
                    }
                    text
                    updated
                }
                ... on MemberAdded {
                    actor {
                        id
                        username
                    }
                    id
                    member {
                        id
                        username
                    }
                    room {
                        id
                    }
                    updated
                }
                ... on MemberRemoved {
                    actor {
                        id
                        username
                    }
                    id
                    member {
                        id
                        username
                    }
                    room {
                        id
                    }
                    updated
                }
                ... on MemberUpdated {
                    id
                    member {
                        id
                        username
                    }
                    room {
                        id
                    }
                    updated
                }
                ... on RequestAdded {
                    actor {
                        id
                        username
                    }
                    id
                    request {
                        id
                        user {
                            id
                            username
                        }
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            contributors {
                                id
                                username
                            }
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                        }
                    }
                    room {
                        id
                    }
                    updated
                }
                ... on RequestRemoved {
                    actor {
                        id
                        username
                    }
                    id
                    request {
                        id
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            contributors {
                                id
                                username
                            }
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                        }
                        user {
                            id
                            username
                        }
                        playTime
                    }
                    room {
                        id
                    }
                    updated
                }
                ... on RequestSkipped {
                    actor {
                        id
                        username
                    }
                    id
                    request {
                        id
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            contributors {
                                id
                                username
                            }
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                        }
                        user {
                            id
                            username
                        }
                    }
                    room {
                        id
                    }
                    updated
                }
                ... on RequestStarted {
                    actor {
                        id
                        username
                    }
                    id
                    request {
                        id
                        track {
                            id
                            album
                            artist
                            bitRate
                            codec
                            composer
                            created
                            date
                            duration
                            files {
                                bitRate
                                duration
                                key
                                size
                            }
                            genre
                            roomId
                            size
                            tags
                            title
                            track
                            updated
                            contributors {
                                id
                                username
                            }
                        }
                        user {
                            id
                            username
                        }
                    }
                    room {
                        id
                    }
                    updated
                }
                ... on RoomUpdated {
                    actor {
                        id
                        username
                    }
                    id
                    room {
                        admins {
                            id
                            username
                        }
                        created
                        id
                        invites {
                            email
                            id
                            issuer {
                                id
                                username
                            }
                            user {
                                id
                                username
                            }
                        }
                        members {
                            id
                            username
                        }
                        name
                        spinning
                        topic
                        updated
                    }
                    updated
                    updates {
                        name
                        spinning
                        topic
                    }
                }
                ... on TracksAdded {
                    actor {
                        id
                        username
                    }
                    id
                    room {
                        id
                    }
                    tracks {
                        id
                        album
                        artist
                        bitRate
                        codec
                        composer
                        contributors {
                            id
                            username
                        }
                        created
                        date
                        duration
                        files {
                            bitRate
                            duration
                            key
                            size
                        }
                        genre
                        roomId
                        size
                        tags
                        title
                        track
                        updated
                    }
                    updated
                }
                ... on TracksRemoved {
                    actor {
                        id
                        username
                    }
                    id
                    room {
                        id
                    }
                    tracks {
                        id
                        album
                        artist
                        bitRate
                        codec
                        composer
                        contributors {
                            id
                            username
                        }
                        created
                        date
                        duration
                        files {
                            bitRate
                            duration
                            key
                            size
                        }
                        genre
                        roomId
                        size
                        tags
                        title
                        track
                        updated
                    }
                    updated
                }
                ... on TrackUpdated {
                    actor {
                        id
                        username
                    }
                    id
                    room {
                        id
                    }
                    track {
                        id
                        album
                        artist
                        bitRate
                        codec
                        composer
                        contributors {
                            id
                            username
                        }
                        created
                        date
                        duration
                        files {
                            bitRate
                            duration
                            key
                            size
                        }
                        genre
                        roomId
                        size
                        tags
                        title
                        track
                        updated
                    }
                    updated
                }
            }
        }
    }
`

export default History
