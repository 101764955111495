import { gql } from '@apollo/client'

export const RemoveAdmin = gql`
    mutation RemoveAdmin($roomId: ID!, $adminId: ID!) {
        removeAdmin(roomId: $roomId, adminId: $adminId) {
            id
        }
    }
`

export default RemoveAdmin
