import { gql } from '@apollo/client'

export const RemoveRequest = gql`
    mutation RemoveRequest($roomId: ID!, $requestId: ID!) {
      removeRequest(roomId: $roomId, requestId: $requestId) {
        id
      }
    }
`

export default RemoveRequest
