import { useState } from 'react'
import Spinner from '../widgets/Spinner'
import { useMutation } from '@apollo/client'
import SendVerification from '../queries/SendVerification'

export default function VerificationRequiredModal(props) {

    const [abortController] = useState(new AbortController())
    const [emailSent, setEmailSent] = useState(false)
    const [message, setMessage] = useState('')

    const [sendVerificationMutation, { loading }] = useMutation(SendVerification)

    function logIn(e) {
        props.show('emailLogin')
    }

    async function resendEmail(e) {
        const email = localStorage.getItem('email')
        if (!email) {
            setMessage('Unknown email address.  Try logging in again, and then resend the verification email.')
            return
        }
        try {
            const response = await sendVerificationMutation({ variables: { email } })
            if (response.ok) {
                setEmailSent(true)
                setMessage('The verification email was re-sent.  Please check your inbox.')
            } else {
                const error = response.json()
                setMessage(error.error)
            }
        } catch (err) {
            setMessage(err.message)
        }
    }

    return (
        <div className="Dimmer">
            <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp" onClick={(e)=>{e.stopPropagation()}}>
                <div style={{ textAlign: 'center' }}>
                    <p className="Message">Check your email for a link to verify your account.  Once your account is verified you'll be able to log in.</p>
                    <div>
                        <button disabled={loading || emailSent} onClick={resendEmail}>Resend Verification Email</button>
                        <button style={{ marginLeft: '10px' }} onClick={logIn}>Log In</button>
                    </div>
                   { loading ? <div style={{ height: '30px', padding: '10px' }}><Spinner /></div> : null }
                    { message ? <p className="ErrorMessage">{message}</p> : null }
                </div>
            </div>
            </div>
        </div>
    )
}
