import CurrentUser from '../queries/CurrentUser'
import { useQuery } from '@apollo/client'
import useCurrentRoom from './useCurrentRoom'

export default function useUserIsCurrentRoomAdmin() {
    const { data } = useQuery(CurrentUser)
    const room = useCurrentRoom()
    const user = data?.user
    return room && room.admins.find( admin => admin.id === user?.id )
}
