import { useQuery } from '@apollo/client'
import CurrentUser from '../queries/CurrentUser'
import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'

export default function RoomList(props) {

    const dispatch = useAppDispatch()
    const { data } = useQuery(CurrentUser)
    const rooms = data?.user?.rooms

    function switchRoom(roomId) {
        props.toggleRoomList?.()
        dispatch(Actions.setCurrentRoom(roomId))
    }

    return <div className="RoomList">
        { rooms?.map( room => <div className="RoomItem" key={room.id} onClick={() => switchRoom(room.id)}>{ room.name }</div> ) }
    </div>
}
