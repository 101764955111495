import { gql } from '@apollo/client'

export const CurrentUser = gql`
    query CurrentUser {
        user {
            avatar
            created
            email
            id
            invites {
                id
                issuer {
                    id
                    username
                }
                room {
                    id
                    name
                    owner {
                        id
                        username
                    }
                }
            }
            rooms {
                id
                name
                topic
                spinning
                owner {
                    id
                    username
                }
                admins {
                    id
                    username
                }
                members {
                    id
                    username
                }
            }
            storage
            updated
            username
        }
    }
`

export const CurrentUserSubscription = gql`
    subscription CurrentUser {
        user {
            avatar
            created
            email
            id
            invites {
                id
                issuer {
                    id
                    username
                }
                room {
                    id
                    name
                    owner {
                        id
                        username
                    }
                }
            }
            rooms {
                id
                name
                topic
                spinning
                owner {
                    id
                    username
                }
                admins {
                    id
                    username
                }
                members {
                    id
                    username
                }
            }
            storage
            updated
            username
        }
    }
`

export default CurrentUser
