import { InMemoryCache } from '@apollo/client'

export const tunistryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                tracks: {
                    keyArgs: ["params"],
                    merge(existing = { tracks: [] }, incoming) {
                        // Eliminate dupes.  Since we're always checking the network we'll get repeats.
                        const newTracks = incoming.tracks.filter( track => !existing.tracks.find( e => e.__ref === track.__ref ) )
                        const merged = { cursor: incoming.cursor }
                        merged.tracks = [ ...existing.tracks, ...newTracks ]
                        return merged
                    }
                },
                history: {
                    keyArgs: ["roomId"],
                    merge(existing = { events: [] }, incoming) {
                        const merged = { cursor: incoming.cursor }
                        // We might be getting old events from a history call or new events from the room subscription,
                        // so we need to check whether what we just received belongs at the beginning or the end of
                        // the history.
                        const latestIncomingId = incoming.events[0]?.__ref.split(':')[1]
                        const latestExistingId = existing.events[0]?.__ref.split(':')[1]
                        if (latestIncomingId > latestExistingId) {
                            merged.events = [ ...incoming.events, ...existing.events ]
                        } else {
                            merged.events = [ ...existing.events, ...incoming.events ]
                        }
                        return merged
                    }
                }
            }
        }
    }
})

export default tunistryCache
