import { gql } from '@apollo/client'

export const Room = gql`
    query Room($roomId: ID!) {
        room(id: $roomId) {
            admins {
                id
                username
            }
            created
            id
            invites {
                id
                email
                issuer {
                    id
                    username
                }
                ttl
                user {
                    id
                    username
                }
            }
            members {
                id
                username
            }
            name
            owner {
                id
                username
            }
            spinning
            topic
            updated
        }
    }
`

export default Room
