import { refreshAccessToken } from './refresh'
import { jwtDecode } from 'jwt-decode'

export async function getOrFetchAccessToken() {
    const token = localStorage.getItem('at')
    if (!token || tokenIsExpired(token)) {
        // Check for a valid refresh token
        const refreshToken = localStorage.getItem('rt')
        if (refreshToken && !tokenIsExpired(refreshToken)) {
            console.log(`refreshing access token`)
            return refreshAccessToken(refreshToken)
        }
    }
    return token
}

function tokenIsExpired(token) {
    try {
        const claims = jwtDecode(token)
        return Date.now() / 1000 > claims.exp
    } catch (err) {
        console.log('error decoding token')
        return true
    }
}

export function validTokenExists() {
    return !tokenIsExpired(localStorage.getItem('at')) || !tokenIsExpired(localStorage.getItem('rt'))
}
