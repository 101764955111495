import { gql } from '@apollo/client'

const UpdateTrack = gql`
    mutation UpdateTrack($trackId: ID!, $updates: TrackInput) {
        updateTrack(trackId: $trackId, updates: $updates) {
            id
            album
            artist
            bitRate
            codec
            composer
            contributors {
                id
                username
            }
            created
            date
            duration
            files {
                bitRate
                duration
                key
                size
            }
            genre
            roomId
            size
            tags
            title
            track
            updated
        }
    }    
`

export default UpdateTrack
