import { setContext } from '@apollo/client/link/context'
import { getOrFetchAccessToken } from '../auth/token'

export const authLink = setContext(async (request, { headers = {} }) => {
    // Prevent infinite loop when trying to use the refresh token to get a new access token
    if (request.operationName === 'RefreshAccessToken') return { headers }

    const token = await getOrFetchAccessToken()
    if (token) {
        headers.authorization = `Bearer ${token}`
    }
    return { headers }
})
