import { gql } from '@apollo/client'

export const AcceptInvite = gql`
    mutation AcceptInvite($id: ID!) {
        acceptInvite(id: $id) {
            id
            created
            email
            invites {
                id
                issuer {
                    id
                    username
                }
                room {
                    id
                    name
                    owner {
                        id
                        username
                    }
                }
            }
            rooms {
                id
                name
                topic
                spinning
                owner {
                    id
                    username
                }
                admins {
                    id
                    username
                }
                members {
                    id
                    username
                }
            }
            updated
            username
        }
    }
`

export default AcceptInvite
