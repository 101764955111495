import { gql } from '@apollo/client'

export const RoomQueue = gql`
    query RoomQueue($roomId: ID!) {
        room(id: $roomId) {
            id
            queue {
                id
                playTime
                track {
                    id
                    album
                    artist
                    bitRate
                    codec
                    composer
                    contributors {
                        id
                        username
                    }
                    created
                    date
                    duration
                    files {
                        bitRate
                        duration
                        key
                        size
                    }
                    genre
                    roomId
                    size
                    tags
                    title
                    track
                    updated
                }
                user {
                    id
                    username
                }
            }
        }
    }
`

export default RoomQueue
