import { useQuery } from '@apollo/client'
import { AppContext, AppDispatchContext } from './AppContext'
import { Actions, useAppReducer } from './AppReducer'
import Login from './login/Login'
import Main from './Main'
import CurrentUser, { CurrentUserSubscription } from './queries/CurrentUser'
import './App.css'
import { useEffect } from 'react'

function App() {

    const [appState, dispatch] = useAppReducer()
    const { data, subscribeToMore } = useQuery(CurrentUser)

    useEffect(() => {
        if (data?.user) {
            dispatch(Actions.setCurrentRoom(localStorage.getItem('currentRoom')))
            console.log(`subscribing to user updates`)
            subscribeToMore(({
                document: CurrentUserSubscription,
                updateQuery: (prev, {subscriptionData}) => subscriptionData.data ?? prev
            }))
        }
    }, [data?.user.id])

    return (
        <div className="App">
            <AppContext.Provider value={appState}>
                <AppDispatchContext.Provider value={dispatch}>
                    <div>
                        { appState.loggedIn ? <Main /> : <Login /> }
                    </div>
                </AppDispatchContext.Provider>
            </AppContext.Provider>
        </div>
    )
}

export default App
