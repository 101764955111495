import { gql } from '@apollo/client'

const RefreshAccessToken = gql`
    mutation RefreshAccessToken($refreshToken: String!) {
        refreshAccessToken(refreshToken: $refreshToken) {
            user {
                id
                created
                email
                rooms {
                    id
                    admins {
                        id
                        username
                    }
                    members {
                        id
                        username
                    }
                    name
                    owner {
                        id
                        username
                    }
                    spinning
                    topic
                    updated
                }
                updated
                username
            }
            accessToken
            refreshToken
        }
    }
`

export default RefreshAccessToken
