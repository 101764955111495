import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'

export default function SearchTrackControls(props) {

    const dispatch = useAppDispatch()

    const { track } = props
    function queueTrack() {
        dispatch(Actions.queueTrack(track))
        dispatch(Actions.setPlayMode('local'))
        dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the local play queue.</span>}))
    }

    return (
        <div className="TrackControlButtons">
            <span className="material-symbols-outlined TrackControlButton" onClick={(e) => dispatch(Actions.playTrack(track))}>play_circle</span>
            <span className="material-symbols-outlined TrackControlButton" onClick={() => queueTrack(track)}>playlist_add</span>
        </div>
    )
}
