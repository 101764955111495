import { Actions } from '../AppReducer'
import { useAppContext, useAppDispatch } from '../AppContext'
import { useMutation } from '@apollo/client'
import AddRequest from '../queries/AddRequest'
import useCurrentRoom from './useCurrentRoom'

export default function useQueueTrack(roomId) {

    const dispatch = useAppDispatch()
    const { playMode } = useAppContext()
    const [ addRequestMutation ] = useMutation(AddRequest)

    async function queueTrack(track, silent) {

        function queueLocally() {
            dispatch(Actions.queueTrack(track))
            dispatch(Actions.setPlayMode('local'))
            if (!silent)
                dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the local queue.</span>}))
        }

        async function queueForRoom() {
            try {
                await addRequestMutation({ variables: { roomId: roomId, trackId: track.id } })
                if (!silent)
                    dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the queue.</span>}))
            } catch (error) {
                console.error(`failed to queue track`, error)
            }
        }

        switch (playMode) {
            case 'room': {
                if (roomId)
                    queueForRoom()
                else
                    queueLocally()
                break
            }
            case 'local': {
                queueLocally()
                break
            }
            default:
                console.error(`unknown play mode: ${playMode}`)
        }
    }

    return queueTrack
}
