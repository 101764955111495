import Spinner from '../widgets/Spinner'
import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { useMutation, useQuery } from '@apollo/client'
import CurrentUser from '../queries/CurrentUser'
import AcceptInvite from '../queries/AcceptInvite'
import DeclineInvite from '../queries/DeclineInvite'

export default function Rooms() {

    const { data } = useQuery(CurrentUser)
    const rooms = data?.user?.rooms
    const invites = data?.user?.invites

    const [acceptInvite, { loading: acceptInviteLoading }] = useMutation(AcceptInvite, {
        notifyOnNetworkStatusChange: true
    })

    const [declineInvite, { loading: declineInviteLoading }] = useMutation(DeclineInvite, {
        notifyOnNetworkStatusChange: true
    })

    const dispatch = useAppDispatch()

    async function accept(invite) {
        acceptInvite({ variables: { id: invite.id } })
    }

    async function decline(invite) {
        declineInvite({ variables: { id: invite.id } })
    }

    function navigateToRoom(room) {
        dispatch(Actions.setCurrentRoom(room.id))
        dispatch(Actions.navigateTo('listen'))
    }

    const createRoom = <div className="RoomButtons"><button className="Button" onClick={() => dispatch(Actions.showSheet({ action: 'showCreateRoom' }))}>Create Room</button></div>

    let roomDivs
    if (rooms?.length) {
        roomDivs =
            <div className="RoomGrid">
                <div className="RoomGridHeading" style={{ gridColumn: 1 }}>Name</div>
                <div className="RoomGridHeading" style={{ gridColumn: 2 }}>Topic</div>
                <div className="RoomGridHeading" style={{ gridColumn: 3 }}>Members</div>
                { rooms.map( (room, index) => [
                    <div key={`roomname-${index}`} className="RoomGridName"><span className="Link" onClick={() => navigateToRoom(room)}>{room.name}</span></div>,
                    <div key={`roomtopic-${index}`} className="RoomGridTopic">{room.topic}</div>,
                    <div key={`roommembers-${index}`} className="RoomGridMembers">{room.members.length}</div>,
                ])
            }</div>
    } else {
        roomDivs = <div>You aren't a member of any rooms.</div>
    }

    let inviteSection
    if (invites?.length) {
        inviteSection = (
            <div className="RoomInvites">
                <div className="RoomInviteHeader">Invitations</div>
                <div className="RoomInviteGrid">
                    { invites?.map( (invite, index) => [
                        <div className="RoomInviteGridDiv" key={`${invite.room.id}-1`} style={{ gridRow: index, gridColumn: 1 }}>{ invite.issuer.username }</div>,
                        <div className="RoomInviteGridDiv"  key={`${invite.room.id}-2`} style={{ gridRow: index, gridColumn: 2 }}>{ invite.room.name }</div>,
                        <div className="RoomInviteGridDiv"  key={`${invite.room.id}-3`} style={{ gridRow: index, gridColumn: 3 }}>
                            <button disabled={acceptInviteLoading} className="RoomInviteAcceptButton" onClick={() => accept(invite)}>Accept</button>
                            <button disabled={declineInviteLoading} className="RoomInviteDeclineButton" onClick={() => decline(invite)}>Decline</button>
                            { acceptInviteLoading || declineInviteLoading ? <Spinner /> : null }
                        </div>
                    ]) }
                </div>
            </div>
        )
    }

    return (
        <div className="Rooms">
            { roomDivs }
            { inviteSection }
            { createRoom }
        </div>
    )
}
