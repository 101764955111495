import { useQuery } from '@apollo/client'
import RoomMembers from '../queries/RoomMembers'
import useCurrentRoom from '../hooks/useCurrentRoom'
import ProfileAvatar from '../profile/ProfileAvatar'

export default function RoomMemberList() {
    const room = useCurrentRoom()
    const { data } = useQuery(RoomMembers, { skip: !room, variables: { roomId: room?.id } })
    const members = data?.room.members

    const lines =  members?.map( m => m)
        .sort( (a, b) => a.username < b.username ? -1 : 1 )
        .map( (member, index) =>
            <div key={index} className={`RoomMemberLine ${index % 2 === 0 ? 'Odd' : 'Even'}`}>
                <ProfileAvatar user={member} position={index} />
                <div className="RoomMemberLineUsername">{ member.username }</div>
            </div>
        )

    return <div className="RoomMemberList">
        <div className="RoomMemberListHeader">Room Members</div>
        { lines }
    </div>
}
